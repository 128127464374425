import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/FirldTrips/2023/Class5/1.jpg';
// import p2 from 'assests/Photos/FirldTrips/2023/Class5/2.jpg';
// import p3 from 'assests/Photos/FirldTrips/2023/Class5/3.jpg';
// import p4 from 'assests/Photos/FirldTrips/2023/Class5/4.jpg';
// import p5 from 'assests/Photos/FirldTrips/2023/Class5/5.jpg';
// import p6 from 'assests/Photos/FirldTrips/2023/Class5/6.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar_2023 } from './components';
import Archive_2022 from './Archive_2022';

const CurricularClass52023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/co-curricular-activities/class5/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/co-curricular-activities/class5/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/co-curricular-activities/class5/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/co-curricular-activities/class5/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/co-curricular-activities/class5/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/co-curricular-activities/class5/6.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
      },
      {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      }, 
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },   
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘Field Trip to Sri Bhagyalakshmi Farms’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 5   Date: 7 SEPTEMBER 2023 (Thursday)

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School, Yeshwanthpur organized a field trip for the students of Class 5 to Sri Bhagyalakshmi Farms on Thursday, 7 September 2023. This school field trip was designed to provide our students with hands-on learning experiences related to agriculture, animal husbandry and sustainable farming practices.
        The tour started with a tractor ride over the farms where students were educated about different types of crops, planting techniques, seasonal crops, intercrops, mix farming and much more. It was integrated with Horticulture, Bonsai and Vertical gardening and Animal Husbandry. The Students explored the farm's greenhouses, where they gained insights into growing vegetables and herbs.  
        <br />
        The farm had a beautiful segment where students were able to get close to farm animals. They were offered a unique opportunity to observe the diverse array of birds and aqua life under one roof. 
        <br />
        The main attraction of this farm is ‘The Green Heaven’ nursery which is a botanical paradise for all plant enthusiasts. This beautiful place greeted us with a breathtaking array of vibrant flora that captured the minds of our students. The Farm has various plants but the main attraction was Gerbera Daisy which has a striking appearance and comes in many vibrant colours. 
        <br />
        Overall, it was an enriching experience for our students. It allowed them to connect with nature, gain a deeper understanding of where their food comes from and appreciate the hard work that goes into various flowering types of farming. We hope that this trip will inspire our students to make more informed choices about their food and the environment.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Agriculture is our wisest pursuit because it will in the end contribute most to real wealth, good morals and happiness”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
                <Sidebar_2023/>
             </Box>

             <Box marginBottom={4}>
                <Archive_2022/>
             </Box>

         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default CurricularClass52023;